import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import FormattedMessage from 'components/FormattedMessage'
import Icon from 'components/Icon'
import NavigationItemWithSubmenu from 'components/Header/components/NavigationItem/WithPopover/WithSubmenu'

import { CRONOFY_INTEGRATION_INITIALIZATION_PATH } from 'helpers/constants'

import { RootState } from 'store/reducer'

import classNames from 'helpers/css'

import styles from './index.module.scss'

const UserMenu: React.FC<UserMenuProps> = (props) => {
  const {
    currentUser,
    isCurrentCountryDach,
  } = useSelector((state: RootState) => ({
    currentUser: state.currentUser,
    isCurrentCountryDach: state.headerData.isCurrentCountryDach,
  }), shallowEqual)

  const { upgradeSubscriptionUrlTeam } = props

  return (
    <NavigationItemWithSubmenu
      icon={<Icon name="user-bold" />}
      label={<FormattedMessage id="navigation.myAccount" />}
    >
      {currentUser.hasProviders && (
        <a
          href="/provider-dashboard/requests"
          key="/provider-dashboard/requests"
          className="link link--no-active-color link--no-decoration"
        >
          <FormattedMessage id="navigation.providerDashboard" />
        </a>
      )}

      {currentUser.hasProviders && currentUser.isCronofyEnabled && (
        <a
          href={CRONOFY_INTEGRATION_INITIALIZATION_PATH}
          key={CRONOFY_INTEGRATION_INITIALIZATION_PATH}
          className="link link--no-active-color link--no-decoration"
        >
          <FormattedMessage id="navigation.calendar" />
        </a>
      )}

      {(
        <a
          href="/registration/edit"
          key="/registration/edit"
          className="link link--no-active-color link--no-decoration"
        >
          <FormattedMessage id="navigation.changeAccountData" />
        </a>
      )}

      {currentUser.isPreferredPartnerAdmin && !upgradeSubscriptionUrlTeam && (
        <a
          href="/projects/users"
          key="/projects/users"
          className="link link--no-active-color link--no-decoration"
        >
          <FormattedMessage id="navigation.preferredPartnerUsers" />
        </a>
      )}

      {upgradeSubscriptionUrlTeam && (
        <>
          <p
            className={classNames(styles, 'header__navigation__desktop-user-menu__item--disabled')}
          >
            <FormattedMessage id="navigation.preferredPartnerUsers" />
          </p>
          <a
            href={upgradeSubscriptionUrlTeam}
            key={upgradeSubscriptionUrlTeam}
            className={classNames(
              styles,
              'header__navigation__desktop-user-menu__item--upgrade',
              'link link--no-active-color link--no-decoration',
            )}
            target="_blank"
          >
            <FormattedMessage id="subscription.badge.upgrade" />
          </a>
        </>
      )}

      {currentUser.hasProviders && isCurrentCountryDach && (
        <a
          href="https://partner.eventinc.de/"
          target="_blank"
          className="link link--no-active-color link--no-decoration"
        >
          <FormattedMessage id="navigation.partnerHub" />
        </a>
      )}

      {(
        <a
          href="/logout"
          key="/logout"
          className={classNames(
            styles,
            'header__navigation__desktop-user-menu__item--bold',
            'link link--no-active-color link--no-decoration',
          )}
        >
          <FormattedMessage id="navigation.signOut" />
        </a>
      )}
    </NavigationItemWithSubmenu>
  )
}

type UserMenuProps = {
  upgradeSubscriptionUrlTeam?: string | undefined
}

export default UserMenu
